import './App.scss';
import Menu from './components/menu';
import Main from './components/main';
import Footer from './components/footer';
import "./styles/movie-swipper.css";
import 'swiper/css';
import "swiper/css/navigation";

function App() {
  return (
    <div className="App">
        <Menu></Menu> 
        <Main></Main>
        <Footer></Footer>
    </div>
  );
}

export default App;
