function removeDashes(value){
    if(value === null ){
        return null;
    }

    return value.replaceAll('-','');
}

export function findMovieData(moviesArray, path) {
    const movieData = moviesArray.find(movie =>movie.path === path || removeDashes(movie.path) === path);
    if(!movieData){
        return null;
    }
    return movieData;
  }
