import { FaArrowCircleUp } from "react-icons/fa";


const Footer = () => {

    const onScrollTopClickFn = () => {
        window.scrollTo(0,0);
    }

    return (
        <div className="footer navbar-fixed-bottom shadow bg-dark">

            <div className="text-center">
                
            </div>
        </div>
    )
}

export default Footer;

//<FaArrowCircleUp className="btn-scroll-top" onClick={onScrollTopClickFn} ></FaArrowCircleUp>