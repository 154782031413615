import RowContent from "./containers/row-content";
import RowContentSubtitle from "./containers/row-content-subtitle";


const FichaTecnica = ({fichaTecnica}) => {
    console.log(fichaTecnica);
    if(!fichaTecnica){
        return;
    }

    return <>
        <RowContent>
        <RowContentSubtitle title={"Ficha Técnica"}/>
            {fichaTecnica.map(item => 
                <>
                    <div className="col-6 text-end fw-bold">
                        {item.tag}
                    </div>
                    <div className="col-6">
                        {item.valor instanceof Array ? <ul className="ficha-tecnica-ul">{item.valor.map((value) => 
                           <li key={value}>{value}</li> 
                        )}
                        </ul>
                         : <>{item.valor}</>}
                    </div>
                </>
            )}
            
        </RowContent>
    </>
}

export default FichaTecnica;