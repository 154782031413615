import React from "react";
import { Image, Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";


const ImageSliderModal = (props) => {
    
    const imagePathList = props.imagePathList || [];
    
    
    return (
        <Modal {...props} centered fullscreen contentClassName="no-background"  >
            <ModalHeader closeButton={true} style={{borderBottom:"none"}} onClick={() => props.setShow(false)} closeVariant="white"></ModalHeader>
            <ModalBody>
                <div className="container-fluid h-100" >
                <Swiper style={{ "--swiper-navigation-color": "white"}}
                                                slidesPerView={1}
                                                initialSlide={props.initialSlide}
                                                spaceBetween={20}
                                                navigation={true}
                                                pagination={{   
                                                    clickable: true,
                                                }}
                                                rewind={true}
                                                modules={[Pagination, Navigation]}
                                            >
                                                {imagePathList.map((img, i) => 
                                                <SwiperSlide key={i}>
                                                    <Image src={require(`../pages/filmes${img}`)} className="img-fluid object-fit-contain"></Image>
                                                </SwiperSlide>
                                                )}
                                            </Swiper>
                </div>
            </ModalBody>
            <ModalFooter style={{borderTop:"none"}} onClick={() => props.setShow(false)} closeVariant="white">
                <div></div>
            </ModalFooter>
        </Modal>
    )
}

export default ImageSliderModal;