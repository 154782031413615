import { Image } from "react-bootstrap";
import { Autoplay, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

const Home = () => {


    const baseStyle = {"position": "absolute", 
                        "color":"white",
                        "marginLeft": "30%",
                        "transform": "translate3d(0, 50px, 0)",
                        opacity: 0.5,
                        transition: "all 0.8s ease-out",
                    };

    const slides = [{src: "./slides/4 bilhoes de infinitos 2.jpg", "name": "4 Bilhões de infinitos", "url":"", style: {...baseStyle}, smPositionLeft: "center" },
                    {src: "./slides/Anderson 1.jpg", "name": "Anderson", "url":"", style: {...baseStyle} , smPositionLeft: "80%"},
                    {src: "./slides/Serrão 2.jpg", "name": "Serrão", "url":"", style: {...baseStyle} ,smPositionLeft: "40%"},
                    {src: "./slides/Abdução 3.jpg", "name": "Abdução", "url":"", style: {...baseStyle} },
                    {src: "./slides/Morro do Cemitério 3.jpg", "name": "Morro do cemitério", "url":"", style: {...baseStyle}, smPositionLeft: "30%"},
                ]

    return <>
        <div className="bg-dark">    
        <div className="main-responsive-height" style={{backgroundColor: "black"}}>
        <Swiper style={{ "--swiper-navigation-color": "white"}}
                                                slidesPerView={1}
                                                initialSlide={0}
                                                spaceBetween={20}
                                                navigation={true}
                                                pagination={{   
                                                    clickable: true,
                                                }}
                                                loop={true}
                                                autoplay={{delay: 5000, pauseOnMouseEnter: true}}
                                                modules={[Pagination, Navigation, Autoplay]}
                                            >
                                                {slides.map((slide, i) => 
                                                <SwiperSlide key={i}>
                                                    <div style={{backgroundColor: "#000", maxWidth:1240, display: "initial"}} className="text-center d-lg-inline d-none">
                                                        
                                                        <Image src={require(`${slide.src}`)} className="img-fluid" style={{objectFit:"cover", objectPosition: "center center"}} ></Image>
                                                    </div>
                                                    <div style={{backgroundColor: "#000", maxWidth:1240, display: "initial"}} className="text-center d-inline d-lg-none">
                                                        <Image src={require(`${slide.src}`)} className="img-fluid" style={{objectFit: "cover", objectPosition: `${slide.smPositionLeft} center`, minHeight: 300}} ></Image>
                                                    </div>
                                                </SwiperSlide>
                                                )}
                                            </Swiper>
        </div>
        </div>
    </>
}

export default Home;