import RowContent from "./containers/row-content";
import RowContentSubtitle from "./containers/row-content-subtitle";
import { FaStar} from 'react-icons/fa';

const Premiacoes = ({premiacoes}) => {

    if(!premiacoes || premiacoes.length === 0){
        return;
    }

    return <>

    <RowContent>
        <RowContentSubtitle title="Premiações"></RowContentSubtitle>
        {premiacoes.map(premiacao => 
            <div className="col-12 text-center" key={premiacao}>
                <blockquote><FaStar size={10}></FaStar> {premiacao.nome}</blockquote>
                <figcaption className="blockquote-footer">{premiacao.local}</figcaption> 
            </div>)}
        
    </RowContent>

    </>


}

export default Premiacoes;