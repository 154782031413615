import { Link, useOutletContext } from "react-router-dom";
import Image from "react-bootstrap/esm/Image";
import RowContent from "../../components/containers/row-content";

const MoviesPage = () => {

    const [jsonData] = useOutletContext();
    
    fetchImages(jsonData.filmes);
    
    return (<>
            <div className="bg-dark">
                <div className="container">
                    <RowContent className="g-4">
                {jsonData.filmes
                .map((movie, i) => 
                    <div className="col-12 col-xs-6 col-sm-6 col-md-4 col-lg-3 small-logo-wrapper"  key={movie.path}>
                        <Link relative="true" to={movie.path}>
                            <div className="py-xs-1 text-center">
                                <Image src={movie.image} loading="lazy" className=" img-fluid pointer small-logo" style={{minWidth: 150}}>
                                </Image>
                            </div>
                        </Link>
                    </div>
                )}
                    </RowContent>
                </div>
            </div>
        </>
    );
}

function fetchImages(movies){ 
    movies.forEach(filme => {
        try{
            filme.image = require(`./${filme.path}/${filme.logo}`);
        } catch(e){
            console.error("falha ao carregar imagem:", e);
        }
    });
}

export default MoviesPage;