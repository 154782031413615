import { Form } from "react-bootstrap";
import { FaInstagram ,FaYoutube} from 'react-icons/fa';
import RowContent from "../../components/containers/row-content";
import { Link } from "react-router-dom";
const Contato = () => {

    return (
        <div className="d-flex flex-column justify-content-center align-items-center py-4 main-responsive-height">
        <div className="container">
            <Form>
            <RowContent>
                <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="formItemNome">
                        <Form.Control type="nome" placeholder="Nome" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formItemEmail">
                        <Form.Control type="text" placeholder="E-mail" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formItemMensagem">
                        <Form.Control as="textarea" rows={3} placeholder="Mensagem" /> 
                    </Form.Group>
                </div>
                <div className="col-md-6 text-center">
                    <div><h3>contato@abducaofilmes.com.br</h3></div>
                    <div className="d-flex justify-content-center">
                        <div className="p-2 p-md-4"><Link to={"http://instagram.com/abducaofilmes"} target="_blank"><FaInstagram size={26} title="@abducaofilmes"></FaInstagram></Link></div>
                        <div className="p-2 p-md-4"><Link to={"https://www.youtube.com/@abducaofilmes"} target="_blank"><FaYoutube size={26} title="@abducaofilmes"></FaYoutube></Link></div>
                    </div>
                </div>
            </RowContent>
            </Form>
        </div>
    </div>
    );
}

export default Contato;