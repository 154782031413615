import { Outlet } from "react-router-dom";
import React, { useEffect } from "react";

export async function loader() {
    const jsonData =  await require(`../pages/filmes/data.json`);   
    return jsonData;
}

const Main = ({className}) => {
    const [jsonData, setJsonData] = React.useState({filmes: []});

    useEffect(() =>{
        loader().then(data => setJsonData(data));
    },[]);
    
    return (
        <div className={`main ${className ?? ''}`}>
            <Outlet context={[jsonData, setJsonData]} />
        </div>
    )
}

export default Main;