import { Image } from "react-bootstrap";
import RowContent from "../../components/containers/row-content";
import RowContentSubtitle from "../../components/containers/row-content-subtitle";


const SobreNos = () =>{
    
    return (
        <div className="container main-min-width">
            <RowContent>
                <RowContentSubtitle title="Sobre nós"></RowContentSubtitle>
                <div className="col-md-12 col-lg-6 pb-2 pb-md-2 pb-md-0 text-justify">
                    <div style={{textIndent: '2rem'}}>
                    A Abdução Filmes é uma empresa de cinema independente sediada em Minas Gerais, Brasil,
                    fundada em 2020 por Marcelo Lin, Rodrigo R. Meireles e Marco Antonio Pereira. Desde sua criação,
                    a produtora se diferencia pelo seu compromisso em produzir filmes originais com foco em temas regionais
                    e sociais relevantes ao nosso tempo. 
                    </div>
                    <div style={{textIndent: '2rem'}}>Em seu breve tempo de vida, a empresa vem se tornando uma voz de expressão única da cena cinematográfica de Minas Gerais e do Brasil,
                    ao ter seus trabalhos reconhecidos com centenas de prêmios, estudados em trabalhos acadêmicos em universidades federais e também com filmes 
                    citados em livros didáticos do ensino público. Seus curtas metragens já foram exibidos em festivais como Berlinale, Telluride, PalmSprings, 
                    Hong Kong, Vina del Mar, Fic Valdívia, HotDocs, Regard, Guadalajara, FNC Montreal, Gramado, Festival do Rio, Olhar de Cinema. 
                    </div>
                    <div style={{textIndent: '2rem'}}>
                    Atualmente, 
                    estão desenvolvendo o primeiro longa-metragem da empresa, 'Paisagem de Inverno', 
                    que acaba de receber o prêmio de Melhor Projeto no 14º Brasil Cinemundi - International Coproduction Meeting (2023).
                    </div>
                </div>
                <div className="col-md-12 col-lg-6">
                   <Image src={require('./sobre-nos.jpg')} className="img-fluid"></Image>
                </div>
            </RowContent>
        </div>
    );
}

export default SobreNos;