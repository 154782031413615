import Image from "react-bootstrap/esm/Image";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { useOutletContext, useParams } from "react-router-dom";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { findMovieData } from "../utils/movie-utils";
import ImageSliderModal from "../../components/image-slider-modal";
import React from "react";
import FichaTecnica from "../../components/ficha-tecnica";
import RowContent from "../../components/containers/row-content";
import RowContentSubtitle from "../../components/containers/row-content-subtitle";
import Premiacoes from "../../components/premiacoes";
import Festivais from "../../components/festivais";

const MoviePage = () => {
    const { path } = useParams();
    const [jsonData, setJsonData] = useOutletContext();
    const movieData = findMovieData(jsonData.filmes, path);
    const [modalShow, setModalShow] = React.useState(false);
    const [initialSlide, setInitialSlide] = React.useState(0);

    const showSlide = (index) => {
        setInitialSlide(index);
        setModalShow(true);
    }
    if (!movieData) {
        return <></>;
    }

    const slider = (movieData.slides !== undefined &&
        <RowContent className="movie-imgs-swipper">
            <div className="col">
                <Swiper style={{ "--swiper-navigation-color": "#fff", }}
                    slidesPerView={5}
                    spaceBetween={20}
                    navigation={true}
                    pagination={{
                        clickable: true,
                    }}
                    breakpoints={{
                        200: {
                            slidesPerView: 2,
                            spaceBetween: 10
                        },
                        768: {
                            slidesPerView: 3,
                            spaceBetween: 10,
                        },
                        1024: {
                            slidesPerView: 4,
                            spaceBetween: 20,
                        },
                    }}
                    modules={[Pagination, Navigation]}
                >
                    {movieData.slides.map((img, i) =>
                        <SwiperSlide key={i}>
                            <Image src={require(`./${movieData.path}/slides/${img}`)} className="img-fluid small-slide-img object-fit-cover" onClick={() => showSlide(i)}></Image>
                        </SwiperSlide>
                    )}
                </Swiper>
            </div>
        </RowContent>);

    const fichaTecnica = <FichaTecnica fichaTecnica={movieData.fichaTecnica}></FichaTecnica>
    const premiacoes = <Premiacoes premiacoes={movieData.premios}></Premiacoes>
    const festivais = <Festivais festivais={movieData.festivais}></Festivais>

    if (!movieData) {
        return <></>;
    } else {
        const logoSrc = require(`./${movieData.path}/${movieData.logo}`);
        return (
            <div className="">  
                <div className="container">
                    <RowContent className="text-center" ><h1 className="my-0">{movieData.nome} <span className="ano-duracao-filme ps-2">({movieData.ano} - {movieData.duracao})</span></h1></RowContent>
                    <RowContent>
                        <div className="col-md-12 col-lg-6 text-center py-2 py-lg-0">
                            {movieData.trailer ?
                                <div className="video-responsive"><iframe title={movieData.title} src={movieData.trailer} allowFullScreen ></iframe></div>
                                : <Image src={logoSrc} className="img-fluid"></Image>}

                        </div>
                        <div className="col py-2 py-lg-0">
                            <RowContentSubtitle title={"Sinopse"}/>
                            
                            <div className="text-justify">
                                <ReactMarkdown className="md-text">{movieData.sinopse instanceof Array ? movieData.sinopse.join('') : movieData.sinopse}</ReactMarkdown>
                            </div>
                        </div>
                    </RowContent>
                    {slider}
                    {fichaTecnica}
                    {premiacoes}
                    {festivais}
                </div>
                <ImageSliderModal show={modalShow} onHide={() => setModalShow(false)} imagePathList={movieData.slides.map((img, i) => `/${movieData.path}/slides/${img}`)}
                    setShow={setModalShow} initialSlide={initialSlide}>
                </ImageSliderModal>
            </div>
        );
    }
}


export default MoviePage;