import RowContent from "./containers/row-content";
import RowContentSubtitle from "./containers/row-content-subtitle";
import { FaVideo} from 'react-icons/fa';

const Festivais = ({festivais}) => {

    if(!festivais || festivais.length === 0){
        return;
    }

    return <>

    <RowContent>
        <RowContentSubtitle title="Festivais"></RowContentSubtitle>
        {festivais.map(festival => 
            <div className="col-12 text-center" key={festival}>
                <blockquote><FaVideo size={10}></FaVideo> {festival.nome}</blockquote>
            </div>)}
        
    </RowContent>

    </>


}

export default Festivais;