import { Container, Image, Nav, Navbar } from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom"; 
import  logoSmall from '../assets/logo_abducao_small.svg';
import React from "react";
import { useEffect } from "react";
const Menu = () => {
  
  const [expanded, setExpanded] = React.useState(false);
  const {pathname} = useLocation();

  useEffect(() => {
    setExpanded(false); // Close the navigation panel
    window.scrollTo(0, 0);
  }, [ pathname ]);

    return (
      <Navbar expanded={expanded} expand="md" bg="dark" variant="dark" className="text-uppercase py-0 shadow fixed-top fs-5" >
        <Container>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" className="ml-auto"  onClick={() => setExpanded(!expanded)}/> 
        <Navbar.Brand to="/" className="d-sm-block d-md-none menu" ><Image src={logoSmall} className="img-fluid h-100"  ></Image></Navbar.Brand>
          <Navbar.Collapse id="responsive-navbar-nav text-nowrap">
            <Nav className="me-auto">
              <ul className="navbar-nav">
                <li>  
                  <Nav.Link to="/home" as={NavLink} className={({ isActive }) => (isActive ?? "active")}>Home</Nav.Link>
                </li>
                <li>
                  <Nav.Link to="/filmes" as={NavLink} className={({ isActive }) => (isActive ?? "active")} >Filmes</Nav.Link>
                </li>
                <li>
                  <Nav.Link to="/sobre-nos" as={NavLink} className={({ isActive }) => (isActive ?? "active")}>Sobre nós</Nav.Link>
                </li>
                <li>
                  <Nav.Link to="/contato" as={NavLink} className={({ isActive }) => (isActive ?? "active")}>Contato</Nav.Link>
                </li>
              </ul>
            </Nav>
          </Navbar.Collapse>
        <Navbar.Brand to="/" className="d-none d-md-block py-0">
          <Image src={logoSmall} className="img-fluid menu" ></Image>
        </Navbar.Brand>
        
        </Container>
      
      </Navbar>
    );
}

export default Menu;

