import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import './index-sm.css'
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import MoviePage from './pages/filmes/movie-page';
import MoviesPage from './pages/filmes/movies-page';
import Contato from './pages/contato/contato';
import SobreNos from './pages/sobre-nos/sobrenos';
import Home from './pages/home/home';


const root = ReactDOM.createRoot(document.getElementById('root'));
const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>,
    children: [
      {
        index: true,
        element: <Home/>
      },
      {
        path: "/home",
        element: <Home/>
      },
      {
        path: "/filmes",
        element: <MoviesPage/>
      },
      {
        path: "/filmes/:path",
        element: <MoviePage/>
      },
      {
        path: "/contato",
        element: <Contato/>
      },
      {
        path: "/sobre-nos",
        element: <SobreNos/>
      }
    ]
  },
  {
    path: "*",
    element: <Navigate to="/home" replace></Navigate>
  }
  
]);
root.render(
  <React.StrictMode>
     <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
